<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <card :title="title">
      <v-menu slot="toolbar" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" outlined dark v-on="on">
            İşlemler
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="instructorAddModal = true">
            Eğitmen Ekle
          </v-list-item>
        </v-list>
      </v-menu>
      <v-data-table
        :headers="headers"
        :items="instructors"
        :loading="loading"
        :single-expand="true"
        show-expand
      >
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'instructor',
              params: { instructorId: item.id, schoolId }
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <b-table-simple>
              <b-tr>
                <b-td>Telefon:</b-td>
                <b-td>{{ item.phone }}</b-td>
              </b-tr>
              <b-tr>
                <b-td>Oluşturma Tarihi:</b-td>
                <b-td>{{ item.createdAt | formatDate(2) }}</b-td>
              </b-tr>
            </b-table-simple>
          </td>
        </template>

        <template v-slot:item.actions="{ item }">
          <router-link
            :to="{
              name: 'instructor',
              params: { instructorId: item.id, schoolId }
            }"
            v-slot="{ navigate }"
          >
            <v-icon small class="mr-2" @click="navigate">mdi-pencil</v-icon>
          </router-link>
          <v-icon
            small
            @click="
              deletePromt = true;
              deleteId = item.id;
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </card>
    <DeletePromt
      :visible="deletePromt"
      @close="deletePromt = false"
      @done="deleteInstructor()"
    ></DeletePromt>
    <InstructorAdd
      :schoolId="schoolId"
      :visible="instructorAddModal"
      @close="instructorAddModal = false"
      @done="getInstructorList()"
    ></InstructorAdd>
  </div>
</template>

<script>
import InstructorAdd from './InstructorAdd';
import DeletePromt from '@/view/content/components/DeletePromt';

export default {
  props: ['schoolId'],
  components: { InstructorAdd, DeletePromt },
  data() {
    return {
      title: 'Eğitmen Listesi',
      headers: [
        { text: 'İsim', value: 'name' },
        { text: 'Soyisim', value: 'surname' },
        { text: 'Eposta', value: 'email' },
        { text: 'İşlemler', value: 'actions' }
      ],
      instructors: [],
      instructorAddModal: false,
      deletePromt: false,
      deleteId: null,
      loading: true
    };
  },
  mounted() {
    this.getInstructorList();
  },
  methods: {
    async getInstructorList() {
      try {
        const { data } = await this.axios.get(
          `admin/schools/${this.schoolId}/instructors`
        );

        this.instructors = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteInstructor() {
      try {
        await this.axios.delete('admin/schools/'+this.schoolId+'/instructors/' + this.deleteId);

        this.getInstructorList();
        this.deletePromt = false;
        this.deleteId = null;
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Silme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    }
  }
};
</script>
