<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Eğitmen Ekle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" md="12" name="email">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Eposta"
                  v-model="form.email"
                  @blur="$v.form.email.$touch()"
                ></v-text-field>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  props: ['visible', 'schoolId'],

  mixins: [validationMixin],

  validations: {
    form: {
      email: { required, email }
    }
  },

  data() {
    return {
      loading: true,
      form: {
        email: ''
      }
    };
  },
  methods: {
    async submit() {
      try {
        this.$v.$touch();

        if (!this.$v.$anyError) {
          await this.axios.post(
            'admin/schools/' + this.schoolId + '/addInstructor',
            this.form
          );
          this.$emit('done');
          this.close();
          this.$store.dispatch('setToast', {
            text: 'Başarılı bir şekilde eklendi!',
            color: 'success'
          });
        }
      } catch (e) {
        if (e.response?.status === 404) {
          this.$store.dispatch('setToast', {
            text: 'Girilen eposta adresine sahip eğitmen bulunamadı!',
            color: 'danger'
          });
        } else {
          this.$store.dispatch('setToast', {
            text: 'Ekleme esnasında hata meydana geldi!',
            color: 'danger'
          });
          console.log(e);
        }
      }
    },
    close() {
      this.$emit('close');
      this.$v.$reset();
      this.form = {
        schoolId: this.schoolId
      };
    }
  }
};
</script>
